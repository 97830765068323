<ng-container [ngSwitch]="isClassExists('text-completion')">

  <ng-template [ngSwitchCase]="true">
    <span [formGroup]="userForm" class="text-completion">
      <span [innerHtml]="input.Label|keepHtml"></span>
      <label class="text-completion-label">
        <input [maxNumber]="input" [minNumber]="input" [formControlName]="input.ControlId+idExtension" type="text"
          [tabindex]="input.Pointed?-1:0" [readonly]="input.ReadOnly"
          [value]="userForm.get(input.ControlId+idExtension).value"
          [id]="input.ControlId+idExtension" (keyup)="onKeyUp($event)" [size]="input|sizeField"
          [placeholder]="input.Placeholder?input.Placeholder:''" (focus)="onFocus(input.ControlId + idExtension)">
        <span class="text-completion-span" [innerHtml]="input.EdingChar|keepHtml"></span>
        <mat-error>
          <div class="text-completion-error"
            *ngIf="userForm.get(input.ControlId+idExtension).invalid && input.ReadOnly">
            {{validation(userForm.controls[input.ControlId+idExtension])}}</div>
        </mat-error>
      </label>
    </span>
  </ng-template>

  <ng-template [ngSwitchCase]="false">
    <ng-container [ngSwitch]="isClassExists('text-completion-before')">

      <ng-template [ngSwitchCase]="true">
        <div [formGroup]="userForm" class="text-completion">
          <label class="text-completion-label text-completion-before">
            <input [formControlName]="input.ControlId+idExtension" [readonly]="input.ReadOnly"
              [value]="userForm.get(input.ControlId+idExtension).value | numberFormat:input.ControlId+idExtension:input.ValueType"
              type="text" [id]="input.ControlId+idExtension" [size]="input|sizeField"
              [placeholder]="input.Placeholder?input.Placeholder:''" (keyup)="onKeyUp($event)"
              (focus)="onFocus(input.ControlId + idExtension)">
            <span class="text-completion-span" [innerHtml]="input.EdingChar|keepHtml"></span>
            <mat-error>
              <div class="text-completion-error" *ngIf="userForm.get(input.ControlId+idExtension).invalid">
                {{validation(userForm.controls[input.ControlId+idExtension])}}</div>
            </mat-error>
          </label>
          <span [innerHtml]="input.Label|keepHtml"></span>
        </div>
      </ng-template>
      <ng-template [ngSwitchCase]="false">

        <ng-container [ngSwitch]="isClassExists('text-editor')">
          <!-- טקסט שניתן לעריכה בעת בחירת שקבוקס ונחסם בעת שהשקבוקסלא בחור -->
          <ng-template [ngSwitchCase]="true">
            <span class="text-editor" [id]="input.ControlId+idExtension+'textEditor'">
              <label class="text-editor-label">
                <span [innerHtml]="input.Label|keepHtml"></span>
                <span class="c-checkbox">
                  <input [type]="input.ValueType" [formControl]="userForm.get(input.ControlId+idExtension)" size="10"
                    [id]="input.ControlId+idExtension" [placeholder]="input.Placeholder?input.Placeholder:''"
                    [attr.disabled]="CheckboxEnableEditorText?null:''" (focus)="onFocus(input.ControlId + idExtension)">
                  <input type="checkbox" [id]="input.ControlId+idExtension+'checkbox'" class="screenreader"
                    (change)="onCheckboxChangeFocusEditorText($event,input.ControlId+idExtension)"
                    [(ngModel)]="CheckboxEnableEditorText" />
                  <label aria-label="לחץ כאן לעריכה " role="תיבת סימון" [for]="input.ControlId+idExtension+'checkbox'">
                    <img src="./assets/img-lg/pen_edit.svg" /></label>
                </span>
              </label>
              <mat-error>{{validation(userForm.controls[input.ControlId+idExtension])}}</mat-error>
            </span>
          </ng-template>

          <ng-template [ngSwitchCase]="false">
            <!--  דפולטיבי -->
            <mat-form-field appearance="outline" [floatLabel]="input.Label">
              <mat-label><span [innerHtml]="input.Label|keepHtml"></span><span class="label-required"
                  *ngIf='input.Validators && input.Validators.Required'> *</span></mat-label>
              <input matInput [id]="input.ControlId+idExtension" [placeholder]="input.Placeholder"
                [type]="input.ValueType" [formControl]="userForm.get(input.ControlId+idExtension)"
                (focus)="onFocus(input.ControlId + idExtension)" />
              <mat-error> {{validation(userForm.controls[input.ControlId+idExtension])}}</mat-error>
              <mat-hint *ngIf='input.Note' class="input-note" [innerHtml]="input.Note|keepHtml"></mat-hint>
            </mat-form-field>
            <app-tooltip style="margin-right: -15px; z-index: 1;position: relative;" *ngIf="input.Tooltip!=undefined"
              [tooltipContent]="input.Tooltip"></app-tooltip>
          </ng-template>
        </ng-container>
      </ng-template>
    </ng-container>
  </ng-template>
</ng-container>