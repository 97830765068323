import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from '../../../../store/reducer';
import { FormComponent } from '../../structureObject';
import { CounterActions } from '../../../../store/actions';
import { FormGroup } from '@angular/forms';
import * as JsonObjects from '../../classes';
import { ProgrammingExpressionService } from '../../services/programmingExpression.service';
import { AccountService } from 'src/app/account.service';
import { Section } from '../../classes';
import { Observable, Subject } from 'rxjs';


@Component({
  selector: 'app-button-ref-level',
  templateUrl: './buttonRefLevel.component.html',
  styleUrls: ['./buttonRefLevel.component.scss']
})
export class ButtonRefLevelComponent implements OnInit, OnDestroy {
  statusUserSecondaryProcess: FormComponent.eStatusUser;//סטאטוס שלב ממלא טופס בתהליך סיום..
  private subscription: Subscription = new Subscription();
  @Input() button: JsonObjects.Button;//אם הנתונים באים מההזנת התוכן
  @Input() indication: string = "";//אינדיקציה לאיזה שלב מצביע
  @Input() content: string;//אינדיקציה לאיזה שלב מצביע
  @Input() userForm: FormGroup = null;//טופס משתמש
  @Output() saveDataFrom: EventEmitter<void> = new EventEmitter();//שמירת פקדים ברדקס במידה ותטופס תקין
  @Output() invalidUserForm: EventEmitter<void> = new EventEmitter();//
  
  menu_page: JsonObjects.RefJsonFile = { Num: "", CompName: "", Template: "", Hierarchy: "", Children: [] };//איחסון כל השלבים מהשרת
  currentProcess: JsonObjects.Process;//הגדרות תהליך נוכחי
  currentLevel: JsonObjects.RefJsonFile;//השלב הנוכחי בו השתמש נמצא
  data$: Observable<JsonObjects.RefJsonFile>;//הנתונים מהשרת
 
 private activeRequest: boolean = false;
  
  /**
   *route הראוטר הנוכחי עם המיקום בשלבים
   *router אחראי לנווט בפועל לשלב אחר 
   *ngRedux רדקס אחראי להחזיק את הנתונים מרדקס
   *actions מכיל את כל הפעולות שאפשר לבצע על הרדקס
   */
  constructor(private router: Router, private ngRedux: NgRedux<IAppState>, private actions: CounterActions, public programmingExpressionService: ProgrammingExpressionService, private account  :AccountService) {

   
  }
  ngOnInit(): void {
    this.subscription.add(this.ngRedux.select(o => o.FormSettings.SecondaryProcess.StatusUser).subscribe((statusUser: FormComponent.eStatusUser) => {
      this.statusUserSecondaryProcess = statusUser
    }));

  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  clickRefLevel() {
  if (!this.activeRequest) {
    this.activeRequest = true; // מסמן שהקריאה התחילה
  
    // אחרי 3 שניות, אם הקריאה עדיין לא הסתיימה, מאפסים את activeRequest
    const timeout = setTimeout(() => {
      this.activeRequest = false;
      console.warn('Timeout reached, resetting activeRequest.');
    }, 1500);
  
    this.clickRefLevelAction()
      .finally(() => {
        clearTimeout(timeout); // מנקה את הטיימר אם הקריאה הסתיימה בזמן
        this.activeRequest = false; // מסמן שהקריאה הסתיימה
      });
  } else {
    console.log('Action in progress, ignoring further calls.');
  }
  
  }


  clickRefLevelAction(): Promise<void> {
    return new Promise((resolve) => {
      // כאן תבצע את הלוגיקה שלך
        if (this.button && this.button.ProgrammingExpression && this.button.ProgrammingExpression.Expression) {
      this.programmingExpressionService.activatingProgrammerCodeControl(this.userForm.controls, this.button, "");////הפעלת הפקדים שיש להם קוד מתכנת
      this.ngRedux.dispatch(this.actions.actions.updateUserForm(this.userForm.controls))//הכנס את הפקדים לטופס משתמש ברידקס
    }
    if (this.indication == FormComponent.eStatusNavigate.pointerLevel) //המניו אחראי על הניווט כאשר מדובר על קפיצה לשלב אחר
    {
      this.ngRedux.dispatch(this.actions.actions.changeNavigateLevel(FormComponent.eStatusNavigate.pointerLevel, this.button.LevelNum));
    }
    else if (this.indication == FormComponent.eStatusNavigate.prevInProcess || this.indication == FormComponent.eStatusNavigate.prevSecondaryProcess)
      this.prev();
    else
      if (this.indication == FormComponent.eStatusNavigate.nextInProcess || this.indication == FormComponent.eStatusNavigate.nextSecondaryProcess || this.indication == 'lastSecondaryProcess')
        this.onSubmit();
      else
        if (this.indication == 'lastInProcess')/////////////לבדוק
          this.onSubmit();
    });
  }
  public prev(): void {//מעבר לשלב הקודם
    if (this.indication == FormComponent.eStatusNavigate.prevInProcess || (this.indication == FormComponent.eStatusNavigate.prevSecondaryProcess && this.statusUserSecondaryProcess == FormComponent.eStatusUser.StartProcess))
      this.ngRedux.dispatch(this.actions.actions.changeNavigateLevel(FormComponent.eStatusNavigate.prevInProcess, this.create_UUID().toString()));
    else//ניווט מקונן תהליך משני
      this.ngRedux.dispatch(this.actions.actions.changeNavigateLevel(FormComponent.eStatusNavigate.prevSecondaryProcess, this.create_UUID().toString()));
  }

  public onSubmit() {
    if (!this.userForm.invalid) {//אם לא השתנה הטופס הוא לא מגדיר שהטופס חוקי למרות שהוא כן
      if (this.indication == FormComponent.eStatusNavigate.nextInProcess || this.indication == FormComponent.eStatusNavigate.nextSecondaryProcess)//אם השלב אינו אחרון
      {
        if (this.indication == FormComponent.eStatusNavigate.nextSecondaryProcess) {
          if (this.ngRedux.getState().FormSettings.SecondaryProcess.StatusUser == FormComponent.eStatusUser.InLastLevel) {
            this.ngRedux.dispatch(this.actions.actions.changeStatusUserSecondaryProcess(FormComponent.eStatusUser.EndProcess));
            this.indication = FormComponent.eStatusNavigate.nextInProcess;
          }
        }
      }
      this.next();//עבור לשלב הבא            
    }
  }
 
  public next() {//מעבר לשלב הבא
    if (this.indication == FormComponent.eStatusNavigate.nextInProcess  ) 
    {console.log(this)
    //if(this.processNum!='DocumentAttachment_9P2oA8QoA02kkHd0GSwhRg_') 
      this.ngRedux.dispatch(this.actions.actions.changeNavigateLevel(FormComponent.eStatusNavigate.nextInProcess, this.create_UUID().toString()));
      
    
    }
    else
      if (this.indication == FormComponent.eStatusNavigate.nextSecondaryProcess)
        this.ngRedux.dispatch(this.actions.actions.changeNavigateLevel(FormComponent.eStatusNavigate.nextSecondaryProcess, this.create_UUID().toString()));
      else
        if (this.indication == "lastInProcess") {
          this.saveDataFrom.next();//שמירת המקטע האחרון
          this.account.setAccount();
          this.router.navigateByUrl('/l/ViewFiles/display?processNum=customer_registration_4456')//displayPDF
        }
  }
  public getError(){
    const controls = this.userForm.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            console.log(name)
            console.log(controls[name].errors)
        }
    }
  }
  //המטרה היא להזניק את ההאזנות ל changeNavigateLevelאין שימוש כלל במספר הרנדומלי הזה 
  create_UUID() {//יוצר מספר רנדומלי עבור כל ריענון של האתר
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }
}