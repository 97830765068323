import {Component ,HostListener,OnInit} from '@angular/core';
import { AccountService } from './account.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit  {
  @HostListener('window:beforeunload', ['$event'])
  onWindowClose(event: any): void {
    this.account.addAccount();
    localStorage.clear();
 }
  constructor(private account : AccountService, private router: Router) { }

  ngOnInit() {
    this.router.navigate(['']);
  }   
}


