import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { Observable, of, Subscription } from 'rxjs';
import { switchMap, startWith, distinctUntilChanged, debounceTime, catchError } from 'rxjs/operators';
import { AutocompleteService } from '../../services/autocomplete.service'
import { CustomValidation } from '../../custom-validation';
import { UserValueEnterrd } from '../../services/user-value-entered.service';
import * as JsonObjects from '../../classes';

@Component({
    selector: 'app-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit, OnDestroy {

    @Input() input: JsonObjects.Autocomplete;//הפקד הנוכחי
    @Input() currentComponent: JsonObjects.RefJsonFile;//המקטע הנוכחי עם המאפיינים שלו
    @Input() userForm: FormGroup;//טופס משתמש

    validation = CustomValidation.validation;//קבלת הודעות השגיאה לפקד
    idExtension: string = '';//מזהה עותק
    filteredOptions: Observable<string[]>;//אפשרויות מסוננות לפקד
    dependentControl: AbstractControl;//מזהה הפקד שתלויים בו
    valueDependentControl: string;//ערך הפקד שתלויים בו
    forbiddenChars = ['<', '>', '=', '#', '@', '!', '$', '%', '^', '*','+', '/', '\\', '{', '}', '?', ':', ';', '~', '`', '&', ']', '[']; // תווים אסורים


    private subscription: Subscription = new Subscription();

    constructor(private autocomplete: AutocompleteService, private userValueService: UserValueEnterrd) {

    }

    ngOnInit() {
        this.idExtension = this.currentComponent.Copy != null ? `_COPY_${this.currentComponent.Copy}` : '';//הכנסת מזהה עותק
        var currentControl = this.userForm.controls[this.input.ControlId + this.idExtension];//שליפת הפקד הנוכחי מהטופס- כדי להאזין לערכי המשתמש
        if (this.input.TypeXML == "streets" || this.input.TypeXML == "bankBranches") {//אם הפקד הוא לרחובות אז יש לו תלוייות בפקד ישובים
            // ואם הפקד הוא סניפי בנק אז יש לו תלוייות בפקד בנקים
            this.dependentControl = this.userForm.controls[this.input.DependsControl.Field + this.idExtension];//שליפת פקד שתלוי בו- פקד ישובים/ בנקים
            if (this.dependentControl == null)//אם הפקד שתלוי בו- ישובים/בנקים אינו באותו שלב
                //קבלת ערך פקד תלוי- ישוב/בנק מהרידקס
                this.valueDependentControl = this.userValueService.selectUserValueEntered({ sectionId: this.input.DependsControl.Section, fieldId: this.input.DependsControl.Field })

            else {
                this.subscription.add(this.userForm.controls[this.input.DependsControl.Field + this.idExtension].valueChanges.pipe(distinctUntilChanged()).subscribe(//תאזין לכל שינוי טקסט של הפקד שתלוי בו
                    (value) => {
                        currentControl.updateValueAndValidity();//ותעדכן את ערך הפקד ותבדוק ולידציה
                        // if (value == "") {
                        //     this.userForm.controls[this.input.ControlId + this.idExtension].setValue("");//תרוקן את רחובות לדוג אם ערים לא בחור
                        // }
                        this.userForm.controls[this.input.ControlId + this.idExtension].setValue("");//תרוקן את רחובות לדוג אם ערים לא בחור
                    }))
            }
        }

        if (currentControl)
            this.filteredOptions = currentControl.valueChanges.pipe(//אוספת ארוע לפקד - כל פעם שמשתנה הערך שבו תעדכן את רשימת האפשרויות מהשרת
                //debounceTime(1000),
                distinctUntilChanged(),
                startWith(' '),
                switchMap(value => this.BeforeFilterValues(this.input, value)),
                catchError((err)=>{console.log(err);return of([])})
            );
    }
    fillSelectByType(input, value) {
        return this.input.TypeXML == "streets" ?
            this._streetFilter(value) ://קבלת אפשרויות של רחובות
            input.TypeXML == "settlements" ?
                this._settlementFilter(value) ://קבלת אפשרויות של ישובים
                input.TypeXML == "bankBranches" ?
                    this._bankBranchesFilter(value) ://קבלת אפשרויות של סניפי בנק
                    input.TypeXML == "banks" ?
                        this._banksFilter(value) ://קבלת אפשרויות של בנק
                        null
    }

    onKeyDown(event: KeyboardEvent) {
        if (this.forbiddenChars.includes(event.key)) {
          event.preventDefault(); // מונע את הקלט של התו
        }
    }

    BeforeFilterValues(input, value){
        const regex = new RegExp(`[${this.forbiddenChars.join('\\')}]`, 'g');
        const matches = value.match(regex);
        let newValue=value;
        if (matches) {
          newValue = value.replace(regex, '');
          this.userForm.controls[this.input.ControlId + this.idExtension].setValue(newValue);
        }
        return this.fillSelectByType(input, newValue)
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    private _settlementFilter(value: string): Promise<string[]> {//קבלת אפשרויות של ישובים 
        return this.autocomplete.GetSettlements(value).toPromise().then(names => {
            return names ? (names as JsonObjects.Settlement[]).map(s => s.Name) : [];
        })
    }

    private _streetFilter(value: string): Promise<string[]> {//קבלת אפשרויות של רחובות 
        return this.autocomplete.GetStreets(value, this.dependentControl ? this.dependentControl.value : this.valueDependentControl).toPromise().then(names => {
            return names ? (names as JsonObjects.Street[]).map(s => s.Name) : [];
        })
    }

    private _banksFilter(value: string): Promise<string[]> {//קבלת אפשרויות של בנקים 
        return this.autocomplete.GetBanks(value).toPromise().then(names => {
            return names ? (names as JsonObjects.Bank[]).map(s => s.Name) : [];
        })
    }

    private _bankBranchesFilter(value: string): Promise<string[]> {//קבלת אפשרויות של סניפי בנק 
        return this.autocomplete.GetBankBranchs(value, this.dependentControl ? this.dependentControl.value : this.valueDependentControl).toPromise().then(names => {
            //return names ? (names as JsonObjects.BankBranch[]).map(s => s.Name) : [];
            return names ? (names as JsonObjects.BankBranch[]).map(s => s.Name + ' | ' + s.Code + " | " + s.Address) : [];
        })
    }

}